// src/utils/fetchWrapper.js
import Cookies from 'js-cookie';

const fetchWrapper = async (url, options = {}) => {
  const token = Cookies.get('authToken'); // Token'ı 'authToken' cookie'sinden alın

  const headers = {
    'Content-Type': 'application/json',
    ...options.headers, // Mevcut header'ları koruyun
  };

    const fetchOptions = {
    ...options,
    headers,
    credentials: 'include', // cookie'lerin gönderilmesini sağla
  };

  const response = await fetch(url, fetchOptions);
    if (!response.ok) {
        throw response
    }
    return response
};

export default fetchWrapper;