import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface FotoAlbumModalProps {
  toggleModal: () => void;
  data: { id: string };
}

const FotoAlbumModal: React.FC<FotoAlbumModalProps> = ({ toggleModal, data }) => {
  const [fotoAlbumNo, setFotoAlbumNo] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await fetch(process.env.API_URL +'/v2/weddings/fotoAlbumNoUpdate', {
        method: 'POST',
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        contractID: data.id,
        fotoAlbumNo,
        }),
      });

      if (response.ok) {
        // Handle success, e.g., show a success message
        toggleModal();
      } else {
        // Handle error, e.g., show an error message
       // console.error('Failed to update fotoAlbumNo');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Modal show onHide={toggleModal}>
      <Modal.Header closeButton>
        <Modal.Title>Foto Album Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFotoAlbumNo">
            <Form.Label>Foto Album No</Form.Label>
            <Form.Control
              type="text"
              placeholder="Foto Album No"
              value={fotoAlbumNo}
              onChange={(e) => setFotoAlbumNo(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggleModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FotoAlbumModal;