import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import AlbumPackageCheckboxes from "../new/AlbumPackageCheckboxes";
import PaymentList from './paymentList';

function UpdatePhotoContractModal({ show, handleClose, data }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paketTutari, setPaketTutari] = useState(0);
  const [extraTutari, setExtraTutari] = useState(0);
  const [toplamTutar, setToplamTutar] = useState(data.odemeToplam || 0);
  const [initialValues, setInitialValues] = useState({
    damatAdi: '',
    gelinAdi: '',
    irtibatTelefonu: '',
    fotoAlbumDisCekimTarihi: '',
    secilenPaket: [],
    albumPackages: [],
    fotoAlbumEkstra1: false,
    fotoAlbumEkstra2: false,
    fotoAlbumEkstra3: false,
    aciklama: '',
    odemeTuru: 'Nakit',
    odemeIndirim: 0,
    odemeToplam: 0
  });

  const priceData = {
    dijitalPaketFiyat: data.dijitalPaketFiyat || 0,
    goldPaketFiyat: data.goldPaketFiyat || 0,
    elitPaketFiyat: data.elitPaketFiyat || 0,
    lovePaketFiyat: data.lovePaketFiyat || 0,
    hikayePaketFiyat: data.hikayePaketFiyat || 0,
  };

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  useEffect(() => {
    if (show && data) {
      try {
        console.log("Received data:", data);
        const albumPackagesArray = data.albumPackages ? 
          (Array.isArray(data.albumPackages) ? data.albumPackages : JSON.parse(data.albumPackages)) : 
          [];
        
        const selectedPackages = albumPackagesArray?.map(pkg => pkg.name) || [];
        const totalPackagePrice = albumPackagesArray?.reduce((sum, pkg) => sum + Number(pkg.price), 0) || 0;
        
        setPaketTutari(totalPackagePrice);

        // Tarihi düzgün formatla
        const formattedDate = data.fotoAlbumDisCekimTarihi 
          ? moment(data.fotoAlbumDisCekimTarihi).format("YYYY-MM-DDTHH:mm")
          : '';
        
        setInitialValues({
          damatAdi: data.damatAdi || '',
          gelinAdi: data.gelinAdi || '',
          irtibatTelefonu: data.irtibatTelefonu || '',
          fotoAlbumDisCekimTarihi: formattedDate,
          secilenPaket: selectedPackages,
          albumPackages: albumPackagesArray,
          fotoAlbumEkstra1: data.fotoAlbumEkstra1 === 'Klip',
          fotoAlbumEkstra2: data.fotoAlbumEkstra2 === 'Aile Albümü',
          fotoAlbumEkstra3: data.fotoAlbumEkstra3 === 'Extra Fotoğraf',
          aciklama: data.aciklama || '',
          odemeTuru: data.odemeTuru || 'Nakit',
          odemeIndirim: data.odemeIndirim || 0,
          odemeToplam: data.odemeToplam || 0
        });
        
        setLoading(false);
      } catch (err) {
        console.error('Data processing error:', err);
        setError(err.message);
        setLoading(false);
      }
    }
  }, [show, data]);

  useEffect(() => {
    setToplamTutar(Number(data.odemeToplam) + Number(paketTutari) + Number(extraTutari));
  }, [data.odemeToplam, paketTutari, extraTutari]); 

  const validationSchema = Yup.object().shape({
    damatAdi: Yup.string().required('Damat adı zorunludur'),
    gelinAdi: Yup.string().required('Gelin adı zorunludur'),
    irtibatTelefonu: Yup.string().required('İrtibat telefonu zorunludur'),
    fotoAlbumDisCekimTarihi: Yup.string().nullable()
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // Tarih formatını ISO string'e dönüştür
      const formattedDate = values.fotoAlbumDisCekimTarihi 
        ? moment(values.fotoAlbumDisCekimTarihi).toISOString()
        : null;

      const response = await fetch(process.env.API_URL + `/photoContracts/update/${data.id}`, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: data.id,
          data: {
            ...values,
            fotoAlbumDisCekimTarihi: formattedDate,
            secilenPaket: values.secilenPaket?.join("|") || "",
            secilenPaketFiyat: Number(paketTutari) || 0,
            albumPackages: values.secilenPaket?.map((paketName, index) => ({
              name: paketName,
              price: Number(paketTutari)
            })) || [],
            fotoAlbumEkstra1: values.fotoAlbumEkstra1 ? "Klip" : null,
            fotoAlbumEkstra2: values.fotoAlbumEkstra2 ? "Aile Albümü" : null,
            fotoAlbumEkstra3: values.fotoAlbumEkstra3 ? "Extra Fotoğraf" : null,
          },
        }),
      });

    

      toast.success("Sözleşme başarıyla güncellendi");


      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Güncelleme başarısız");
      }

      handleClose();
      toast.success("Sözleşme başarıyla güncellendi");
    } catch (err) {
      console.error("Güncelleme hatası:", err);
      toast.error("Güncelleme sırasında bir hata oluştu: " + err.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleOdemeEkle = async () => {
    try {
    fetch(process.env.API_URL +'/photoContracts/addPay', {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: data.id ,
          data: {
            odemeTuru: document.getElementById('odemeTuru').value,
            odemeAlinan: document.getElementById('yeniOdemeAlinan').value
          },
        }),

      });
      toast.success('Ödeme başarıyla eklendi!');
    } catch (error) {
      toast.error('Ödeme eklenirken hata oluştu: ' + error.message);
    }
  };

  const handlePaymentListClick = (e) => {
    e.preventDefault(); // Prevent default button behavior
    e.stopPropagation(); // Prevent event bubbling
    setShowPaymentModal(true);
  };

  const handlePaymentModalClose = () => {
    setShowPaymentModal(false);
  };

  if (loading) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Yükleniyor...</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="alert alert-danger">
            Hata: {error}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const isAlbumPackagesEmpty = !data.albumPackages || data.albumPackages.length === 0;

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="border-0">
          <Modal.Title>Sözleşme Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                {/* Müşteri Bilgileri Bölümü */}
                <div className="container shadow-sm p-4 mb-4 border bg-white rounded-4">
                  <div className="d-flex justify-content-between align-items-center mb-4 pb-2 border-bottom">
                    <h5 className="m-0">Müşteri Bilgileri</h5>
                    <span className="badge bg-primary px-4 py-2 fs-6">Sözleşme Kodu: {data.contractID}</span>
                  </div>
                  <div className="row g-3">
                    <div className="col-md-4">
                      <label htmlFor="damatAdi" className="form-label">Damat Adı</label>
                      <Field
                        type="text"
                        className={`form-control ${errors.damatAdi && touched.damatAdi ? 'is-invalid' : ''}`}
                        id="damatAdi"
                        name="damatAdi"
                      />
                      <ErrorMessage name="damatAdi" component="div" className="invalid-feedback" />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="gelinAdi" className="form-label">Gelin Adı</label>
                      <Field
                        type="text"
                        className={`form-control ${errors.gelinAdi && touched.gelinAdi ? 'is-invalid' : ''}`}
                        id="gelinAdi"
                        name="gelinAdi"
                      />
                      <ErrorMessage name="gelinAdi" component="div" className="invalid-feedback" />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="irtibatTelefonu" className="form-label">İrtibat Telefonu</label>
                      <Field name="irtibatTelefonu">
                        {({ field }) => (
                          <InputMask
                            {...field}
                            mask="(999) 999-9999"
                            className={`form-control ${errors.irtibatTelefonu && touched.irtibatTelefonu ? 'is-invalid' : ''}`}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="irtibatTelefonu" component="div" className="invalid-feedback" />
                    </div>
                    <div className="col-12">
                      <label htmlFor="fotoAlbumDisCekimTarihi" className="form-label">
                        Dış Çekim Tarihi
                      </label>
                      <Field name="fotoAlbumDisCekimTarihi">
                        {({ field, form }) => (
                          <input
                            type="datetime-local"
                            className={`form-control ${errors.fotoAlbumDisCekimTarihi && touched.fotoAlbumDisCekimTarihi ? 'is-invalid' : ''}`}
                            id="fotoAlbumDisCekimTarihi"
                            value={field.value || ''}
                            onChange={(e) => {
                              const date = e.target.value;
                              form.setFieldValue('fotoAlbumDisCekimTarihi', date);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="fotoAlbumDisCekimTarihi" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                </div>

                {/* Albüm Paketi Bölümü */}
                <div className="container shadow-sm p-4 mb-4 border bg-white rounded-4">
                  <div className="d-flex justify-content-between align-items-center mb-4 pb-2 border-bottom">
                    <h5 className="m-0">Albüm Paketi</h5>
                  </div>
                  {isAlbumPackagesEmpty ? (
                    <div className="row px-5 justify-content-center">
                      <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                        <Field
                          type="checkbox"
                          className="form-check-input"
                          id="dijitalPaket"
                          name="secilenPaket"
                          onClick={(e) => {
                            calculatePaket(e.target.value, e.target.checked);
                          }}
                          value="Dijital Paket"
                          checked={!!data.dijitalPaket}
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="dijitalPaket"
                        >
                          Dijital Paket
                        </label>
                        <label
                          className={`form-check-label ms-2 ${
                            priceData.dijitalPaketFiyat !== data.dijitalPaketFiyat
                              ? "text-warning"
                              : ""
                          }`}
                          htmlFor="dijitalPaket"
                        >
                          {priceData.dijitalPaketFiyat !== data.dijitalPaketFiyat
                            ? priceData.dijitalPaketFiyat
                            : data.dijitalPaketFiyat}
                          ₺
                        </label>
                      </div>
                      <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                        <Field
                          type="checkbox"
                          className="form-check-input"
                          id="goldPaket"
                          name="secilenPaket"
                          onClick={(e) => {
                            calculatePaket(e.target.value, e.target.checked);
                          }}
                          value="Gold Paket"
                          checked={!!data.goldPaket}
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="goldPaket"
                        >
                          Gold Paket
                        </label>
                        <label
                          className={`form-check-label ms-2 ${
                            priceData.goldPaketFiyat !== data.goldPaketFiyat
                              ? "text-warning"
                              : ""
                          }`}
                          htmlFor="goldPaket"
                        >
                          {priceData.goldPaketFiyat !== data.goldPaketFiyat
                            ? priceData.goldPaketFiyat
                            : data.goldPaketFiyat}
                          ₺
                        </label>
                      </div>
                      <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                        <Field
                          type="checkbox"
                          className="form-check-input"
                          id="elitPaket"
                          name="secilenPaket"
                          onClick={(e) => {
                            calculatePaket(e.target.value, e.target.checked);
                          }}
                          value="Elit Paket"
                          checked={!!data.elitPaket}
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="elitPaket"
                        >
                          Elit Paket
                        </label>
                        <label
                          className={`form-check-label ms-2 ${
                            priceData.elitPaketFiyat !== data.elitPaketFiyat
                              ? "text-warning"
                              : ""
                          }`}
                          htmlFor="elitPaket"
                        >
                          {priceData.elitPaketFiyat !== data.elitPaketFiyat
                            ? priceData.elitPaketFiyat
                            : data.elitPaketFiyat}
                          ₺
                        </label>
                      </div>
                      <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                        <Field
                          type="checkbox"
                          className="form-check-input"
                          id="lovePaket"
                          name="secilenPaket"
                          onClick={(e) => {
                            calculatePaket(e.target.value, e.target.checked);
                          }}
                          value="Love Paket"
                          checked={!!data.lovePaket}
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="lovePaket"
                        >
                          Love Paket
                        </label>
                        <label
                          className={`form-check-label ms-2 ${
                            priceData.lovePaketFiyat !== data.lovePaketFiyat
                              ? "text-warning"
                              : ""
                          }`}
                          htmlFor="lovePaket"
                        >
                          {priceData.lovePaketFiyat !== data.lovePaketFiyat
                            ? priceData.lovePaketFiyat
                            : data.lovePaketFiyat}
                          ₺
                        </label>
                      </div>
                      <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                        <Field
                          type="checkbox"
                          className="form-check-input"
                          id="hikayePaket"
                          name="secilenPaket"
                          onClick={(e) => {
                            calculatePaket(e.target.value, e.target.checked);
                          }}
                          value="Hikaye Paket"
                          checked={!!data.hikayePaket}
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="hikayePaket"
                        >
                          Hikaye Paket
                        </label>
                        <label
                          className={`form-check-label ms-2 ${
                            priceData.hikayePaketFiyat !== data.hikayePaketFiyat
                              ? "text-warning"
                              : ""
                          }`}
                          htmlFor="hikayePaket"
                        >
                          {priceData.hikayePaketFiyat !== data.hikayePaketFiyat
                            ? priceData.hikayePaketFiyat
                            : data.hikayePaketFiyat}
                          ₺
                        </label>
                      </div>
                    </div>
                  ) : (
                    <AlbumPackageCheckboxes
                      onPackageChange={(value, isChecked) => {
                        setPaketTutari(prevTutar => {
                          const currentTutar = Number(prevTutar) || 0;
                          return isChecked ? currentTutar + Number(value) : currentTutar - Number(value);
                        });
                      }}
                    />
                  )}
                </div>

                {/* Albüm Bilgileri Bölümü */}
                <div className="container shadow-sm p-4 mb-4 border bg-white rounded-4">
                  <div className="d-flex justify-content-between align-items-center mb-4 pb-2 border-bottom">
                    <h5 className="m-0">Albüm Bilgileri</h5>
                  </div>
                  <div className="row g-4">
                    <div className="col-md-4">
                      <div 
                        className="form-check d-flex justify-content-between align-items-center p-3 border rounded"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          const newValue = !values.fotoAlbumEkstra1;
                          setFieldValue('fotoAlbumEkstra1', newValue);
                          setExtraTutari(prev => newValue ? prev + 1000 : prev - 1000);
                        }}
                      >
                        <div>
                          <Field
                            type="checkbox"
                            className="form-check-input me-2"
                            id="fotoAlbumEkstra1"
                            name="fotoAlbumEkstra1"
                            onClick={(e) => e.stopPropagation()}
                          />
                          <label className="form-check-label" style={{ cursor: 'pointer' }}>
                            Klip
                          </label>
                        </div>
                        <span className="badge bg-secondary">1000₺</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div 
                        className="form-check d-flex justify-content-between align-items-center p-3 border rounded"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          const newValue = !values.fotoAlbumEkstra2;
                          setFieldValue('fotoAlbumEkstra2', newValue);
                          setExtraTutari(prev => newValue ? prev + 750 : prev - 750);
                        }}
                      >
                        <div>
                          <Field
                            type="checkbox"
                            className="form-check-input me-2"
                            id="fotoAlbumEkstra2"
                            name="fotoAlbumEkstra2"
                            onClick={(e) => e.stopPropagation()}
                          />
                          <label className="form-check-label" style={{ cursor: 'pointer' }}>
                            Aile Albümü
                          </label>
                        </div>
                        <span className="badge bg-secondary">750₺</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div 
                        className="form-check d-flex justify-content-between align-items-center p-3 border rounded"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          const newValue = !values.fotoAlbumEkstra3;
                          setFieldValue('fotoAlbumEkstra3', newValue);
                          setExtraTutari(prev => newValue ? prev + 500 : prev - 500);
                        }}
                      >
                        <div>
                          <Field
                            type="checkbox"
                            className="form-check-input me-2"
                            id="fotoAlbumEkstra3"
                            name="fotoAlbumEkstra3"
                            onClick={(e) => e.stopPropagation()}
                          />
                          <label className="form-check-label" style={{ cursor: 'pointer' }}>
                            Extra Fotoğraf
                          </label>
                        </div>
                        <span className="badge bg-secondary">500₺</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Ödeme Bilgileri Bölümü */}
                <div className="container shadow-sm p-4 mb-4 border bg-white rounded-4">
                  <div className="d-flex justify-content-between align-items-center mb-4 pb-2 border-bottom">
                    <h5 className="m-0">Ödeme Bilgileri</h5>
                    <div className="d-flex gap-3">
                      <span className="badge bg-success px-3 py-2 fs-6">Toplam: {toplamTutar}₺</span>
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label htmlFor="aciklama" className="form-label">Açıklama</label>
                      <Field
                        as="textarea"
                        className="form-control"
                        id="aciklama"
                        name="aciklama"
                        rows="3"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="form-label text-muted mb-2">Ödeme Türü</label>
                        </div>
                        <div className="form-control-plaintext">
                        <select
                          className="form-select"
                          id="odemeTuru"
                          name="odemeTuru"
                          defaultValue="Nakit"
                        >
                          <option value="Nakit">Nakit</option>
                          <option value="Havale">Havale</option>
                          <option value="İskonto">İskonto</option>

                        </select>
                        <label
                          className="form-label ms-2 fw-bold"
                          htmlFor="gelinlikFiltresi"
                          style={{ fontSize: ".95em" }}
                        >
                        </label>
                        </div>

                    
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="form-label text-muted mb-2">Tutar</label>
                        </div>
                        <div className="form-control-plaintext">
                        <Field
                          type="text"
                          name="yeniOdemeAlinan"
                          placeholder="İskonto"
                        >
                          {({ field }) => (
                            <InputMask
                              {...field}
                              type="text"
                              className="form-control"
                              id="yeniOdemeAlinan"
                              mask="9999999999"
                              maskChar={""}
                              placeholder=""
                         
                            />
                          )}
                        </Field>
                        <label
                          className="form-check-label ms-2 "
                          htmlFor="yeniOdemeAlinan"
                        >
                        </label>

                        </div>
                        <Button
                          id="ödemeEkle"
                          className="btn btn-warning"
                          onClick={handleOdemeEkle}
                        >
                          Ödeme Ekle
                        </Button>

                      </div>
                    </div>
                  </div>
                </div>

                {/* Bilgi Notu */}
                <div className="alert alert-warning mb-4">
                  <i className="bi bi-info-circle me-2"></i>
                  Sözleşmeyi oluştururken girilen fiyat bilgileriyle (paket ücretleri ve albüm ekstraları gibi) güncel fiyat bilgileri aynı değilse, fiyatlar sarı renkle gösterilir ve sözleşmeyi güncellediğinizde bu fiyatlar değişmez. Fiyatı değiştirmek için başka bir seçenek seçmelisiniz.
                </div>

                {/* Butonlar */}
                <div className="d-flex justify-content-end">
                  <Button variant="secondary" onClick={handleClose} className="me-2">
                    İptal
                  </Button>
                  <Button variant="primary" type="submit">
                    Güncelle
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <ToastContainer position="top-right" autoClose={3000} />
      </Modal>

      {/* Payment List Modal */}
      <Modal 
        show={showPaymentModal} 
        onHide={handlePaymentModalClose}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ödeme Geçmişi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentList photoContractId={data?.id} />
        </Modal.Body>
        <Modal.Footer>
          <button 
              className="btn btn-secondary" 
              onClick={handlePaymentModalClose}
          >
              Kapat
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdatePhotoContractModal;