import React, { useState, useEffect } from "react";
import { Container, Table, Button, Modal, Form, Alert } from "react-bootstrap";

const PersonelList = () => {
  // State management
  const [personels, setPersonels] = useState([]);
  const [show, setShow] = useState(false);
  const [currentPersonel, setCurrentPersonel] = useState({ name: "" });
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Modal handlers
  const handleClose = () => {
    setShow(false);
    setCurrentPersonel({ name: "" });
    setIsEditing(false);
  };
  const handleShow = () => setShow(true);

  // Fetch personels
  const fetchPersonels = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        process.env.API_URL + "/settings/personels",
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Personel Listesi Çekilemedi");
      }

      const data = await response.json();
      setPersonels(data.data.personels);
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Add new personel
  const handleAddPersonel = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        process.env.API_URL + "/settings/personels",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(currentPersonel),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add personel");
      }

      fetchPersonels();
      handleClose();
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Edit personel
  const handleEditPersonel = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        process.env.API_URL + `/settings/personels/${currentPersonel.id}`,
        {
          method: "PATCH",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: currentPersonel.name }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update personel");
      }

      fetchPersonels();
      handleClose();
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete personel
  const handleDeletePersonel = async (id) => {
    if (!window.confirm("Bu personeli silmek istediğinize emin misiniz?")) {
      return; // Exit if the user cancels
    }
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        process.env.API_URL + `/settings/personels/${id}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete personel");
      }

      fetchPersonels();
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Prepare for editing
  const startEditing = (personel) => {
    setCurrentPersonel(personel);
    setIsEditing(true);
    handleShow();
  };

  // Initial fetch
  useEffect(() => {
    fetchPersonels();
  }, []);

  return (
    <Container className="mt-3">
      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}

      <div className="d-flex  justify-content-between align-items-center mb-3">
        <h5>Personel Listesi</h5>
        <Button variant="primary" onClick={handleShow} disabled={isLoading}>
          {isLoading ? "Yükleniyor..." : "Yeni Personel Ekle"}
        </Button>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>İsim</th>
            <th>Oluşturma Tarihi</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="4" className="text-center">
                Personeller yükleniyor
              </td>
            </tr>
          ) : personels.length === 0 ? (
            <tr>
              <td colSpan="4" className="text-center">
                Personel Bulunamadı
              </td>
            </tr>
          ) : (
            personels.map((personel) => (
              <tr key={personel.id}>
                <td>{personel.id}</td>
                <td>{personel.name}</td>
                <td>
                  {personel.createdAt
                    ? new Date(personel.createdAt).toLocaleDateString()
                    : "N/A"}
                </td>
                <td>
                  <Button
                    variant="warning"
                    size="sm"
                    className="me-2"
                    onClick={() => startEditing(personel)}
                    disabled={isLoading}
                  >
                    Düzenle
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDeletePersonel(personel.id)}
                    disabled={isLoading}
                  >
                    Sil
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {/* Modal for Add/Edit Personel */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Personel Güncelle" : "Yeni Personel Ekle"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Personel İsmi</Form.Label>
              <Form.Control
                type="text"
                placeholder="Personel İsmi Gir"
                value={currentPersonel.name}
                onChange={(e) =>
                  setCurrentPersonel({
                    ...currentPersonel,
                    name: e.target.value,
                  })
                }
                disabled={isLoading}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isLoading}
          >
            Kapat
          </Button>
          <Button
            variant="primary"
            onClick={isEditing ? handleEditPersonel : handleAddPersonel}
            disabled={isLoading || !currentPersonel.name.trim()}
          >
            {isLoading ? "İşleniyor..." : isEditing ? "Güncelle" : "Ekle"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PersonelList;
