import React, { createContext, useContext, useState } from "react";

const PrintContext = createContext();

export const usePrintContext = () => {
  const context = useContext(PrintContext);
  return context;
};

export const PrintProvider = ({ children }) => {
  const [printData, setPrintData] = useState({});
  const [shouldPrint, setShouldPrint] = useState(false);
  const [, setCurrentPage] = useState("Contracts");

  const [secondPrintData, setSecondPrintData] = useState({});
  const [fazlaTutar, setFazlaTutar] = useState(0);
  const [printContext, setPrintContext] = useState("");
  const [listCurrentPage, setListCurrentPage] = useState(0);
  const [secondShouldPrint, setSecondShouldPrint] = useState(false);
  const [, setSecondCurrentPage] = useState("ListPrintCanvas");

  const [contractsUpdates, setContractsUpdates] = useState(false);


  const switchToPrintPage = () => {
    setCurrentPage("NewBridalPrintCanvas");
  };

  const switchBackToReality = () => {
    setCurrentPage("NewContract");
  };

  const switchToSecondPrintPage = () => {
    setSecondCurrentPage("ListPrintCanvas");
  };

  const switchBackToSecondReality = () => {
    setSecondCurrentPage("NewContract");
  };

 


  return (
    <PrintContext.Provider
      value={{
        printData,
        setPrintData,
        contractsUpdates,
        setContractsUpdates,
        shouldPrint,
        setShouldPrint,
        switchToPrintPage,
        switchBackToReality,
        secondPrintData,
        setSecondPrintData,
        fazlaTutar,
        setFazlaTutar,
        printContext,
        setPrintContext,
        listCurrentPage,
        setListCurrentPage,
        secondShouldPrint,
        setSecondShouldPrint,
        switchToSecondPrintPage,
        switchBackToSecondReality,
      }}
    >
      {children}
    </PrintContext.Provider>
  );
};
