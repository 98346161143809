import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import turkishMoneySeperator from "../../../Utils/turkishMoneySeperator";
import fetchWrapper from "../../../Utils/fetchWrapper";
import AddExtraForm from './AddExtra'; // Import the AddExtraForm component
import { Modal } from 'react-bootstrap';

function ExtraPackages({ reload, setReload }) {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [updatedValues, setUpdatedValues] = useState({});
  const [showAddForm, setShowAddForm] = useState(false); // Add this line

  useEffect(() => {
    const fetchData = async () => {
      if (!reload) return;
      setLoading(true);
      try {
        const response = await fetchWrapper(
          process.env.API_URL + "/extraPackages/getAll"
        );
        const actualData = await response.json();
        setData(actualData);
        setError(null);
        // Başlangıç değerlerini ayarla
        const initialValues = actualData.reduce((acc, location) => {
          acc[location.id] = {
            name: location.name,
            price: location.price,
            description: location.description,
          };
          return acc;
        }, {});
        setUpdatedValues(initialValues);
      } catch (err) {
        console.log(err);
        if (err.status === 401) {
          setError(
            "401 - Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız."
          );
        } else if (err.message === "Failed to fetch") {
          setError("Veritabanıyla iletişim kurulamadı.");
        } else
          setError(
            "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin."
          );
      } finally {
        setTimeout(() => {
          setLoading(false);
          setReload(false);
        }, 150);
      }
    };

    fetchData();
  }, [reload]);

  const updateLocation = async (id, updatedName, updatedPrice, updatedDescription) => {
    setLoading(true);
    try {
      const response = await fetchWrapper(
        process.env.API_URL + `/extraPackages/update/${id}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            price: updatedPrice,
            name: updatedName,
            description: updatedDescription,
          }),
        }
      );

      if (!response.ok) {
        throw response;
      }
      const actualData = await response.json();
      setData(actualData);
    } catch (err) {
      if (err.status === 401) {
        setError(
          "401 - Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız."
        );
      } else if (err.message === "Failed to fetch") {
        setError("Veritabanıyla iletişim kurulamadı.");
      } else
        setError(
          "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin."
        );
    } finally {
      setTimeout(() => {
        setLoading(false);
        setReload(true);
      }, 150);
    }
  };

  const removeLocation = async (id) => {
    setLoading(true);
    try {
      const response = await fetchWrapper(
        process.env.API_URL + "/extraPackages/remove",
        {
          method: "DELETE",
          body: JSON.stringify({
            id: id,
          }),
        }
      );

      if (!response.ok) {
        throw response;
      }
    } catch (err) {
 
        toast.error(
          "Beklenmedik bir hata oluştur, lütfen daha sonra tekrar deneyin."+err.message,
          {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          }
        );
  
    } finally {
      setTimeout(() => {
        setLoading(false);
        setReload(true);
      }, 150);
    }
  };

  const handleEditClick = (id) => {
    setEditMode(id);
  };

  const handleCancelClick = () => {
    setEditMode(null);
  };

  const handleSaveClick = (id) => {
    const { name, price, description } = updatedValues[id];
    updateLocation(id, name, price, description);
    setEditMode(null);
  };

  const handleValueChange = (id, key, value) => {
    setUpdatedValues((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [key]: value,
      },
    }));
  };

  const handleShowAddForm = () => {
    setShowAddForm(true);
  };

  const handleHideAddForm = () => {
    setShowAddForm(false);
    setReload(true); // Refresh list when modal closes
  };

  const handleAddPackage = async (values) => {
    setLoading(true);
    try {
      const response = await fetchWrapper(
        process.env.API_URL + "/extraPackages/create",
        {
          method: "POST",
          body: JSON.stringify({
            data: values,
          }),
        }
      );

      if (!response.ok) {
        throw response;
      }
      const actualData = await response.json();
      setData(actualData);
      setError(null);
      handleHideAddForm();
    } catch (err) {
      console.log(err);
      setError("Paket eklenirken bir hata meydana geldi");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 150);
    }
  };

  const renderLocations = (locations) =>
    locations.map((location) => (
      <div className="row mb-3" key={location.id}>
        <div className="col-12">
          <div className="card mt-3 shadow-sm">
            <div className="card-body d-flex flex-row justify-content-between align-items-center">
              <div>
                <h5 className="card-title mb-1">
                  Ürün Adı: {location.name}
                </h5>
                {editMode === location.id ? (
                  <div className="input-group mb-1">
                    <input
                      type="text"
                      className="form-control"
                      value={updatedValues[location.id]?.name || ""}
                      onChange={(e) =>
                        handleValueChange(location.id, "name", e.target.value)
                      }
                    />

                    <input
                      type="number"
                      className="form-control"
                      value={updatedValues[location.id]?.price || ""}
                      onChange={(e) =>
                        handleValueChange(location.id, "price", e.target.value)
                      }
                    />
                    <input
                      type="text"
                      className="form-control ms-2 my-auto"
                      value={updatedValues[location.id]?.description || ""}
                      onChange={(e) =>
                        handleValueChange(location.id, "description", e.target.value)
                      }
                      id="description"
                      name="description"
                      placeholder=""
                    />
                  </div>
                ) : (
                  <p className="card-text">
                    Ücreti: {turkishMoneySeperator(location.price)} ₺
                  </p>
                )}
              </div>
              <div>
                {editMode === location.id ? (
                  <>
                    <button
                      className="btn btn-success me-2"
                      onClick={() => handleSaveClick(location.id)}
                    >
                      Kaydet
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={handleCancelClick}
                    >
                      İptal
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-primary me-2 mt-3"
                      onClick={() => handleEditClick(location.id)}
                    >
                      <i className="bi bi-pen" /> Düzenle
                    </button>
                    <button
                      className="btn btn-danger mt-3"
                      onClick={() => removeLocation(location.id)}
                    >
                      Sil
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <div>

<div className="d-flex mt-3 justify-content-between align-items-center mb-3">
        <h5>Ekstran Ürün Listesi</h5>
        <button
        className="btn btn-outline-success mb-3 "
        onClick={handleShowAddForm}
      >
        Yeni Ürün Ekle
      </button>
      </div>

     

      <Modal show={showAddForm} onHide={handleHideAddForm} centered>
        <Modal.Header closeButton>
          <Modal.Title>Yeni Ürün Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddExtraForm
            onSubmit={handleAddPackage}
            onCancel={handleHideAddForm}
            onClose={handleHideAddForm}
          />
        </Modal.Body>
      </Modal>

      {loading ? (
        <div className="d-flex justify-content-center align-center my-auto h-100 mt-4">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : error ? (
        <div className="row ">
          <div className="col-md-4 mx-auto text-center">
            <div className="alert alert-danger mx-0">
              <i className="bi bi-exclamation-circle-fill" />- {error}
            </div>
            <button
              className="btn btn-primary fw-bold focus-ring"
              onClick={() => {
                setReload(true);
              }}
            >
              Tekrar dene
            </button>
          </div>
        </div>
      ) : data && data.length > 0 ? (
        <div>{renderLocations(data)}</div>
      ) : (
        <div className="row">
          <div className="col-md-4 mx-auto text-center mt-4">
            <div className="alert alert-danger mx-0">
              <i className="bi bi-exclamation-circle-fill" /> Veri yok
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExtraPackages;