import React, { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import "./NewPrintCanvas.css";
import { usePrintContext } from "../contextWrapper/PrintContext";

export default function NewWeddingPrintCanvas() {
  const { printData, switchBackToReality, shouldPrint, setShouldPrint } =
    usePrintContext();

  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (shouldPrint === "wedding") {
      handlePrint();

      switchBackToReality();

      setShouldPrint(false);
    }
  }, [
    printData,
    shouldPrint,
    handlePrint,
    switchBackToReality,
    setShouldPrint,
  ]);

  return (
    <>
      <div className="container-fluid bg-white border  d-none">
        <div ref={componentRef} className="" id="printCanvas">
          <div className="row">
            <div className="col-auto ps-0 pe-0 me-0 d-flex align-items-start justify-content-between">
              <div className="text-center fs-1 fw-bold me-3">
                <img src="/cameray.png" alt="logo" className="img-fluid" />
              </div>
 

              <div
                className="text-center fs-3 fw-bold d-flex flex-column align-items-end"
                style={{ lineHeight: "2em" }}
              >
                <h5 className="float-end mb-1" style={{ marginTop: "0" }}>
                  Sözleşme Tarihi: {moment(printData.sozlesmeTarihi).format("DD.MM.YYYY")}
                </h5>

                <div className="font-weight-bold fs-2">{process.env.NEXT_PUBLIC_FIRMA_TITLE}

                  <br/>
                  DÜĞÜN ÇEKİMLERİ SÖZLEŞMESİ
                </div>
              </div>
            </div>
          </div>
          {/* Çizgi Eklentisi */}
          <div className="row">
            <div className="col">
              <hr style={{ borderTop: "2px solid black", margin: "10px 0" }} />

              <span>
              Bu sözleşmeye stnaden aşağıda belrtlmş tarh ve saatlerde Altınpark Tesslernde yapılacak olan organzasyonun
Altınpark Tessler le anlaşmalı fotoğraf frması olarak organzasyon başından sonuna kadar ücretsz fotoğraf ve vdeo
çekm hzmet alacaksınız. Ücretsz hzmet olarak verdğmz Fotoğraf ve Vdeo çekmlernz organzasyon tarhnden br
ay sonra djtal olarak teslm alablrsnz.
-Teslmat usb bellek le yapılmaktadır teslm almaya gelrken getreceğnz usb bellek sıfır olmak zorundadır. Talep
edldğ takdrde usb ücret ödenerek yüklemesn yaptırıp geldğnzde hazır br şeklde teslm alablrsnz.
⁃ Organzasyon sürecnde salondak çekmlernz frmamız tarafından yapılacaktır. Farklı br fotoğraf frması salon
çersne alınmamaktadır. Farklı br frmadan salon çersnde hkaye çekm ve klp çekm hzmet alma talebnz
olduğunda anlaşmış olduğunuz fotoğraf frmasından salona grş bedel olarak 10000 tl ödeme zorunluluğu vardr.
Frmamızın sze sunduğu ücretsz hzmetlern dışında olan ücretl ek hzmetler ve paketlermz tarafımızca belrtlmştr.
Frmamızdan talep edlen ek hzmetler ve bedeller aşağıda belrtlmştr.  
              </span>

              <hr style={{ borderTop: "2px solid black", margin: "10px 0" }} />

            </div>
          </div>
          {/* Çizgi Bitiş */}
          <div className="row mb-2">
            <div className="col ms-4">
              <b className="pe-1">PAKET DEATYLARI:</b>
            </div>
            <div className="col text-end me-4 ">
              <b className="pe-1">AÇIKLAMA</b>
              <br/>
              <br/>

              <br/>
              <br/>
              <br/>
           

            </div>
            <div className=" ms-4">
            <b className="pe-1">DIŞ ÇEKİM TARİHİ:</b>
            <b className="pe-1">SAAT</b>
            </div>
          </div>
          <hr style={{ borderTop: "2px solid black", margin: "10px 0" }} />

          <div className="row">
            <div className="col-3 ms-4 ps-4 me-2 pe-0 border-end">
              {/*MÜŞTERİ BİLGİLERİ BAŞLANGIÇ*/}
              <div className="row ps-2" style={{ lineHeight: "1m" }}>
                <div className="row  fw-semibold text-sm">MÜŞTERİ BİLGİLERİ</div>
              </div>
              <div
                className="row mt- pt-4"
                style={{
                  fontSize: ".85em",
                  letterSpacing: "0px",
                  lineHeight: "1.45em",
                }}
              >
                <div className="row ps-3 pe-0">
                  <div className="row">
                    <div className="col ps-3">DAMAT</div>
                  </div>
                  <div className="row">
                    <div className="col fw-bold">İSİM SOYİSİM:</div>
                  </div>
                  <div
                    className="row ps-4 pt-1 pe-0"
                    style={{ height: "50px" }}
                  >
                    {printData.damatAdi}
                  </div>
                  <div className="row">
                    <div className="col">TELEFON:</div>
                  </div>
                  <div className="row ps-4 pe0" style={{ height: "40px" }}>
                    {printData.damatTelefonu}
                  </div>
                  <div className="row">
                    <div className="col ps-3">GELİN</div>
                  </div>
                  <div className="row">
                    <div className="col fw-bold">İSİM SOYİSİM:</div>
                  </div>
                  <div className="row ps-4 pe-0" style={{ height: "45px" }}>
                    {printData.gelinAdi}
                  </div>

                  <div className="row">
                    <div className="col">TELEFON:</div>
                  </div>
                  <div className="row ps-4 pe-0" style={{ height: "35px" }}>
                    {printData.gelinTelefonu}
                  </div>

                  <div className="row">
                    <div className="col">DÜĞÜN TARİHİ:</div>
                  </div>
                  <div className="row ps-4 pe-0" style={{ height: "35px" }}>
                    {moment(printData.dugunTarihi).format("DD.MM.YYYY")}
                  </div>
                  <div className="row">
                    <div className="col fw-bold" style={{ fontSize: "1.25em" }}>
                      ADRES:
                    </div>
                  </div>
                  <div
                    className="row ps-4 pe-0"
                    style={{ height: "95px", lineHeight: "1.35em" }}
                  >
                    {printData.adres}
                  </div>
                </div>
                <hr className="" style={{ width: "75%" }} />
                <div className="row">
                  <div className="col fw-bold" style={{ fontSize: "1.35em" }}>
                    İRTİBAT:
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col fw-bold pt-2"
                    style={{
                      height: "100px",
                      fontSize: "1.35em",
                      letterSpacing: ".8px",
                    }}
                  >
                    05540161638
                  </div>
                </div>
                <hr className="" style={{ width: "75%" }} />
                <div className="row">
                  <div className="col fw-bold fs-5 text-center py-2">
                    ALTINPARK {process.env.BASLIK}
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col fw-bold pt-2 pb-2"
                    style={{ fontSize: "1.25em" }}
                  >
                    ADRES:
                  </div>
                </div>
                <div className="row ps-4 fw-bold mb-2">
                  GÖKÇE ÖREN MAH. ESKİ ORHANELİ YOLU 6.KM OSMANGAZİ/BURSA
                </div>
                <hr className="" style={{ width: "75%" }} />
              </div>
              {/*MÜŞTERİ BİLGİLERİ BİTİŞ*/}
            </div>
            <div
              className="col ms-1 d-flex flex-column"
              style={{ fontSize: ".95em" }}
            >
              {printData.dugunSalonu && (
                <>
                  <div className="row ps-2">
                    {/*DÜĞÜN SALONU BİLGİLERİ BAŞLANGIÇ*/}
                    <div
                      className="col fw-bold mt-3"
                      style={{ fontSize: "1.1em" }}
                    >
                      • DÜĞÜN BİLGİLERİ
                    </div>
                  </div>
                  <div className="row ms-1 mt-1">
                    <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                      SALON ADI:
                    </div>
                    <div className="col-auto ps-0 pe-1">
                      {printData.dugunSalonu.name}
                    </div>
                  </div>
                  <div className="row ms-1 mt-1">
                    <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                      SALON ÜCRETİ:
                    </div>
                    <div className="col-auto ps-0 pe-1">
                      {printData.dugunSalonu.price} ₺
                    </div>
                  </div>
                  <div className="row ms-1 mt-1">
                    <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                      DÜĞÜN TARİHİ:
                    </div>
                    <div className="col-auto ps-0 pe-1">
                      {printData.dugunTarihi
                        ? moment
                            .utc(printData.dugunTarihi)
                            .format("YYYY-MM-DD HH:mm")
                        : "Belirtilmedi"}
                    </div>
                  </div>
                  <div className="row ms-1 mt-1">
                    <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                      KİŞİ SAYISI:
                    </div>
                    <div className="col-auto ps-0 pe-1">
                      {printData.kisiSayisi}
                    </div>
                  </div>
                </>
              )}
              <div className="row ps-2">
                {/*DÜĞÜN SALONU BİLGİLERİ BAŞLANGIÇ*/}
                <div className="col fw-bold mt-3" style={{ fontSize: "1.1em" }}>
                  • PAKET
                </div>
              </div>
              <div className="row ms-1 mt-1">
                <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                  PAKET TUTARI:
                </div>
                <div className="col-auto ps-0 pe-1">
                  {printData.paketFiyati} ₺
                </div>
              </div>
              <div className="row ms-1 mt-1">
                <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                  EK ÜCRET:
                </div>
                <div className="col-auto ps-0 pe-1">
                  {printData.odemeEkstra || "-"} ₺
                </div>
              </div>
              {/*DÜĞÜN SALONU BİLGİLERİ BİTİŞ*/}
              <div className="row ps-2">
                <div className="col-3 pe-0">
                  {printData.aciklama && (
                    <>
                      <div
                        className="col fw-bold mt-3"
                        style={{ fontSize: "1.1em" }}
                      >
                        • AÇIKLAMA & EK BİLGİLER
                      </div>
                      <div
                        className="mt-1 ps-2"
                        style={{
                          maxHeight: "150px",
                        }}
                      >
                        {printData.aciklama}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="mt-auto mb-4">
                <hr className="" style={{ width: "95%" }} />

                <div className="row mt-3">
                  <div className="col-3 fs-5 fw-bold">SATICI:</div>
                  <div className="col-4">
                    <div className="row fs-5 fw-bold">ALICI:</div>
                    <div className="row">
                      <div className="col p-0 pe-1" style={{ height: "45px" }}>
                        {printData.damatAdi}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row">
                      <div className="col p-0 fw-bold">TOPLAM:</div>
                      <div className="col p-0 mt-auto">
                        {printData.odemeToplam} ₺
                      </div>
                    </div>
                    <div className="row">
                      <div className="col p-0 fw-bold">KAPORA:</div>
                      <div className="col p-0 mt-auto ps-1">
                        {printData.odemeAlinan} ₺
                      </div>
                    </div>
                    {Number(printData.odemeIndirim) > 0 && (
                      <div className="row">
                        <div className="col p-0 fw-bold">İSKONTO:</div>
                        <div className="col p-0 mt-auto ps-1">
                          {printData.odemeIndirim} ₺
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col p-0 fw-bold">KALAN:</div>
                      <div className="col p-0 mt-auto">
                        {printData.odemeKalan} ₺
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}