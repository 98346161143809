import React, { useState, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import axios from 'axios';

function AlbumPackageCheckboxes({ onPackageChange }) {
  const [paketler, setPaketler] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    const fetchAlbumPackages = async () => {
      setLoading(true);
      try {
        const apiUrl = process.env.API_URL + '/settings/albumpackages';
        const response = await axios.get(apiUrl, {
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        if (!response.data || !response.data.payload) {
          setError('API yanıtında veri yok');
          return;
        }

        const apiData = response.data.payload;
        const selectedPackages = values.secilenPaket || [];
        
        const initialPaketler = apiData.map((paket) => {
          const isSelected = selectedPackages.includes(paket.name);
          if (isSelected) {
            onPackageChange(Number(paket.price) || 0, true);
          }
          return {
            name: paket.name,
            value: paket.price,
            color: paket.color || '#000000',
            checked: isSelected,
          };
        });

        setPaketler(initialPaketler);
      } catch (err) {
        console.error('Error details:', {
          message: err.message,
          response: err.response,
          status: err.response?.status,
        });
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAlbumPackages();
  }, []);

  const handleCheckboxChange = (paket, e) => {
    const isChecked = e.target.checked;
    const value = Number(paket.value) || 0;

    // Mevcut seçili paketleri al
    const currentSelected = values.secilenPaket || [];
    
    // Yeni seçili paketler listesini oluştur
    let newSelected;
    if (isChecked) {
      newSelected = [...currentSelected, paket.name];
    } else {
      newSelected = currentSelected.filter(name => name !== paket.name);
    }
    
    // Formik state'ini güncelle
    setFieldValue('secilenPaket', newSelected);
    
    // Callback'i çağır
    onPackageChange(value, isChecked);
  };

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div className="text-danger">Hata: {error}</div>;
  }

  return (
    <div className="row g-4">
      {paketler.map((paket, index) => {
        const isChecked = values.secilenPaket?.includes(paket.name) || false;
        return (
          <div key={index} className="col-md-4">
            <div 
              className="form-check d-flex justify-content-between align-items-center p-3 border rounded cursor-pointer"
              style={{ 
                backgroundColor: paket.color + '20',
                cursor: 'pointer'
              }}
              onClick={() => handleCheckboxChange(paket, { target: { checked: !isChecked } })}
            >
              <div>
                <input
                  type="checkbox"
                  className="form-check-input me-2"
                  checked={isChecked}
                  onChange={(e) => handleCheckboxChange(paket, e)}
                  onClick={(e) => e.stopPropagation()}
                />
                <label className="form-check-label" style={{ cursor: 'pointer' }}>
                  {paket.name}
                </label>
              </div>
              <span className="badge bg-secondary">{paket.value}₺</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AlbumPackageCheckboxes;