import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import WEDDINGTIME from "../../../constants/weddingTime";
import weddingTimeFormatter from "../../../Utils/weddingTimeFormatter";
import UpdatePhotoContractModal from "../../photo/update/UpdatePhotoContractModal";
import axios from "axios";
 
export default function UpdateWeddingContractModal({
  toggleModal,
  closeModal,
  data,
  locationData,
}) {
  const [locations] = useState(locationData);
  const [paketFiyati, setPaketFiyati] = useState(data.paketFiyati);
  const [totalPrice, setTotalPrice] = useState(data.odemeToplam);
  const [extraPrice, setExtraPrice] = useState(data.odemeEkstra);

  const [newPayment, setNewPayment] = useState(Number(data.odemeAlinan));

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [contractData, setContractData] = useState(null);

  useEffect(() => {
    if (isNewModalOpen) {
      // Replace `your_api_endpoint` with your actual API endpoint
      fetch(process.env.API_URL + `/photoContracts/${data.fotoAlbumNo}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setContractData(data);
        })
        .catch(error => {
          console.error('Error fetching contract data:', error);
        });
    }
  }, [isNewModalOpen, data.id]);



  const handlePaymentChange = (e) => {
    const newPaymentValue = Number(e.target.value);
    const calculatedPayment = Number(data.odemeAlinan) + newPaymentValue;

    setNewPayment(calculatedPayment);
  };

  const handleExtraPriceChange = (e) => {
    const newExtraPriceValue = Number(e.target.value);

    setExtraPrice(Number(newExtraPriceValue));

    setTotalPrice(paketFiyati + newExtraPriceValue);
  };

  const openNewModal = () => {
    setIsNewModalOpen(true);
   // closeModal();
  };

  const formikRef = useRef();

  const handleSubmitOutsideForm = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const initialValues = {
    damatAdi: data.damatAdi,
    gelinAdi: data.gelinAdi,
    damatTelefonu: data.damatTelefonu,
    gelinTelefonu: data.gelinTelefonu,
    adres: data.adres,
    dugunTarihi: moment.utc(data.dugunTarihi).format("YYYY-MM-DD") || null,
    dugunZamani: weddingTimeFormatter(
      moment.utc(data.dugunTarihi).format("HH:mm")
    ),
    aciklama: data.aciklama,
    kisiSayisi: data.kisiSayisi,
    dugunSalonu: data.dugunSalonu.name,
    yeniOdemeAlinan: undefined,
    odemeIndirim: data.odemeIndirim,
    odemeEkstra: data.odemeEkstra,
  };

  const ValidationSchema = Yup.object().shape({
    damatAdi: Yup.string()
      .min(3, "Damat adı 3 karakterden az olamaz")
      .max(41, "Damat adı 40 karakterden fazla olamaz")
      .required("Damat adı gerekli"),
    damatTelefonu: Yup.string().required("Telefon numarası gerekli"),
    gelinAdi: Yup.string()
      .min(3, "Gelin adı 3 karakterden az olamaz")
      .max(41, "Gelin adı 40 karakterden fazla olamaz")
      .required("Gelin adı gerekli"),
    gelinTelefonu: Yup.string().required("Telefon numarası gerekli"),
    adres: Yup.string()
      .min(3, "Adres 3 karakterden az olamaz")
      .max(126, "Adres 125 karakterden fazla olamaz")
      .required("Adres gerekli"),
    dugunTarihi: Yup.date().required("Düğün tarihi gerekli"),
    aciklama: Yup.string()
      .max(101, "100 karakterden fazla olamaz")
      .notRequired(),
    yeniOdemeAlinan: Yup.number()
      .test("max", "Ön ödeme toplam tutardan fazla olamaz", function () {
        return newPayment <= totalPrice;
      })
      .min(1, "Alınan ödeme tutarı sıfırdan yüksek olmalıdır")
      .notRequired(),
    dugunSalonu: Yup.string().required("Duğün salonu gereklidir"),
    kisiSayisi: Yup.number()
      .typeError("Kişi sayısı rakamlardan oluşmalıdır")
      .min(1, "Kişi sayısı sıfırdan yüksek olmalıdır")
      .required("Kişi sayısı gereklidir"),
    odemeEkstra: Yup.number()
      .typeError("Ek ödeme tutarı rakamlardan oluşmalıdır")
      .min(1, "Ek ödeme tutarı sıfırdan yüksek olmalıdır")
      .notRequired(),
    odemeIndirim: Yup.number()
      .notRequired()
      .test(
        "max",
        "Ödeme iskontosu toplam tutardan fazla olamaz",
        function (value) {
          if (!value) return true;
          return value <= totalPrice;
        }
      ),
  });

  const validateDamatTelefonu = (value) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.length !== 11) {
      return "Telefon numarası eksik olmamalıdır";
    }

    return undefined;
  };

  const validateGelinTelefonu = (value) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.length !== 11) {
      return "Telefon numarası eksik olmamalıdır";
    }

    return undefined;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const temizDamatTel = values.damatTelefonu.replace(/\D/g, "");
    const temizGelinTel = values.gelinTelefonu.replace(/\D/g, "");

    let wedTime = "T13:00";
    if (values.dugunZamani === "aksam") {
      wedTime = "T21:00";
    }
    const finalWeddingDateTime = values.dugunTarihi + wedTime;
    let dugunSalonuId = values.dugunSalonu
      ? locations[
          locations.findIndex(
            (location) => location.name === values.dugunSalonu
          )
        ].id
      : null;

    if (!dugunSalonuId) {
      setSubmitting(false);
      return toast.error("Düğün salonu olmadan sözleşme oluşturamazsınız", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    const result = fetch(process.env.API_URL + "/weddingContracts", {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: data.id,
        oldLocation:
          dugunSalonuId !== data.dugunSalonuId &&
          locations[
            locations.findIndex(
              (location) => location.name === data.dugunSalonu.name
            )
          ],
        newLocation:
          dugunSalonuId !== data.dugunSalonuId &&
          locations[
            locations.findIndex(
              (location) => location.name === values.dugunSalonu
            )
          ],
        data: {
          damatAdi: values.damatAdi || null,
          gelinAdi: values.gelinAdi || null,
          adres: values.adres,
          damatTelefonu: temizDamatTel || null,
          gelinTelefonu: temizGelinTel || null,
          dugunTarihi: new Date(finalWeddingDateTime) || null,
          aciklama: values.aciklama || null,
          odemeTuru: values.odemeTuru,
          kisiSayisi: Number(values.kisiSayisi),
          paketFiyati:
            Number(values.paketFiyati) === Number(paketFiyati)
              ? Number(values.paketFiyati)
              : Number(paketFiyati),
          odemeToplam: Number(totalPrice),
          odemeAlinan: Number(values.yeniOdemeAlinan) || data.odemeAlinan || 0,
          odemeKalan: values.yeniOdemeAlinan
            ? totalPrice - Number(values.yeniOdemeAlinan)
            : totalPrice - Number(data.odemeAlinan),
          odemeEkstra: Number(extraPrice) || null,
          dugunSalonuId: dugunSalonuId,
          odemeIndirim:
            Number(values.odemeIndirim) > 0 ? values.odemeIndirim : null,
        },
      }),
    });

    const response = await result;

    if (response.ok) {
      toast.success("Sözleşme başarıyla güncellendi!", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error("Sözleşme güncellenirken bir hata meydana geldi", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 1200));

    setSubmitting(false);
    closeModal();
  };

  return (
    <>
      <Modal
        show={true}
        onHide={toggleModal}
        size={"xl"}
        fullscreen="lg-down"
        scrollable
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-muted fs-6">
              Sözleşme Kodu: 120{data.contractID} - Toplam Fiyat: {totalPrice}₺
            </div>
          </Modal.Title>
        </Modal.Header>

        <>
          <Modal.Body>
            <Formik
              initialValues={initialValues}
              validationSchema={ValidationSchema}
              onSubmit={handleSubmit}
              innerRef={formikRef}
            >
              {({ values, handleChange }) => (
                <Form>
                  <div className="row mb-3">
                    <div className="fs-5 fw-bold text-center">
                      Müşteri Bilgileri
                    </div>
                  </div>
                  <div className="row px-5 px-md-0 mb-1 justify-content-center">
                    <div className="form-floating col-lg-4 mb-3">
                      <Field
                        type="text"
                        className="form-control"
                        id="damatAdi"
                        name="damatAdi"
                        placeholder="Damat Adı"
                      />
                      <label className="form-label ms-3" htmlFor="damatAdi">
                        Damat Adı
                      </label>
                      <ErrorMessage
                        name="damatAdi"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-4 mb-3">
                      <Field
                        type="text"
                        className="form-control"
                        id="gelinAdi"
                        name="gelinAdi"
                        placeholder="Gelin adı"
                      />
                      <label className="form-label ms-3" htmlFor="gelinAdi">
                        Gelin Adı
                      </label>
                      <ErrorMessage
                        name="gelinAdi"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>
                  <div className="row px-5 px-md-0 mt-1 justify-content-center">
                    <div className="form-floating col-lg-4 mb-3">
                      <Field
                        name="damatTelefonu"
                        placeholder="Damat Telefon No"
                        validate={validateDamatTelefonu}
                      >
                        {({ field }) => (
                          <InputMask
                            {...field}
                            mask="0 (999) 999-9999"
                            maskChar=""
                            className="form-control"
                            id="damatTelefonu"
                            name="damatTelefonu"
                            placeholder="0 (   ) ___-____"
                          />
                        )}
                      </Field>
                      <label
                        className="form-label ms-3"
                        htmlFor="damatTelefonu"
                      >
                        Damat Telefon No
                      </label>
                      <ErrorMessage
                        name="damatTelefonu"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-4 mb-3">
                      <Field
                        name="gelinTelefonu"
                        placeholder="Gelin Telefon No"
                        validate={validateGelinTelefonu}
                      >
                        {({ field }) => (
                          <InputMask
                            {...field}
                            mask="0 (999) 999-9999"
                            maskChar=""
                            className="form-control"
                            id="gelinTelefonu"
                            name="gelinTelefonu"
                            placeholder="0 (___) ___-____"
                          />
                        )}
                      </Field>
                      <label
                        className="form-label ms-3"
                        htmlFor="gelinTelefonu"
                      >
                        Gelin Telefon No
                      </label>
                      <ErrorMessage
                        name="gelinTelefonu"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>
                  <div className="row px-5 px-md-0 mt-1 justify-content-center">
                    <div className="form-floating col-lg-8 mb-3">
                      <Field
                        type="text"
                        className="form-control"
                        id="adres"
                        name="adres"
                        placeholder="Adres"
                      />
                      <label className="form-label ms-3" htmlFor="adres">
                        Adres
                      </label>
                      <ErrorMessage
                        name="adres"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  {data.fotoAlbumNo &&
                    data.fotoAlbumNo.length > 0 && (
                      <div className="row px-5 px-md-0 mt-1 justify-content-center">
                        <div className="fs-5 fw-bold text-center">
                        <a id="openModalNew" onClick={openNewModal}>
                          Foto Albüm Mevcut Detaylar için tıklayınız
                        </a>

                        </div>
                    
                      </div>
                    )}

                  <hr className="w-75 mx-auto" />

                  <div className="row mb-3">
                    <div className="fs-5 fw-bold text-center">
                      Düğün Tarihi & Düğün Salonu
                    </div>
                  </div>

                  <div className="row px-5 px-md-0 mb-1 justify-content-center">
                    <div className="form-floating col-lg-4 mb-3">
                      <Field
                        type="date"
                        className="form-control"
                        id="dugunTarihi"
                        onChange={handleChange}
                        name="dugunTarihi"
                        placeholder="Düğün Tarihi"
                      />
                      <label className="form-label ms-3" htmlFor="Düğün Tarihi">
                        Düğün Tarihi
                      </label>
                      <ErrorMessage
                        name="dugunTarihi"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-4 mb-3">
                      <select
                        className="form-select"
                        id="dugunZamani"
                        name="dugunZamani"
                        defaultValue={values.dugunZamani}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        {WEDDINGTIME &&
                          WEDDINGTIME.length > 0 &&
                          WEDDINGTIME.map((time, index) => (
                            <option key={index} value={time.value}>
                              {time.label}
                            </option>
                          ))}
                      </select>
                      <label
                        className="form-label ms-2 fw-bold"
                        htmlFor="dugunZamani"
                        style={{ fontSize: ".95em" }}
                      >
                        Düğün Zamanı
                      </label>

                      <ErrorMessage
                        name="dugunZamani"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="col-lg-4 form-floating">
                      <select
                        className="form-select"
                        id="dugunSalonu"
                        name="dugunSalonu"
                        onChange={(e) => {
                          setTotalPrice(
                            locations[
                              locations.findIndex(
                                (location) => location.name === e.target.value
                              )
                            ].price + Number(extraPrice)
                          );
                          setPaketFiyati(
                            locations[
                              locations.findIndex(
                                (location) => location.name === e.target.value
                              )
                            ].price
                          );
                          handleChange(e);
                        }}
                        defaultValue={data.dugunSalonu.name}
                      >
                        {locations &&
                          locations.length > 0 &&
                          locations.map((location) => (
                            <option key={location.id} value={location.name}>
                              {location.name}
                            </option>
                          ))}
                      </select>
                      <label
                        className="form-label ms-2 fw-bold"
                        htmlFor="dugunSalonu"
                        style={{ fontSize: ".95em" }}
                      >
                        Düğün Salonu
                      </label>

                      <ErrorMessage
                        name="dugunSalonu"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <hr className="w-75 mx-auto" />

                  <div className="row mb-3">
                    <div className="fs-5 fw-bold text-center">
                      Açıklama ve Kişi Sayısı
                    </div>
                  </div>

                  <div className="row mb-1 px-5 px-md-0 justify-content-center">
                    <div className="form-floating col-lg-6 mb-3">
                      <Field name="aciklama" placeholder="Sıra No">
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="aciklama"
                            mask=""
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>

                      <label
                        className="form-check-label ms-2"
                        htmlFor="aciklama"
                      >
                        Açıklama
                      </label>
                      <ErrorMessage
                        name="aciklama"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>

                    <div className="form-floating col-md-2 mb-3">
                      <Field name="kisiSayisi" placeholder="Kişi Sayısı">
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="kisiSayisi"
                            mask=""
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>

                      <label
                        className="form-check-label ms-2"
                        htmlFor="kisiSayisi"
                      >
                        Kişi Sayısı
                      </label>
                      <ErrorMessage
                        name="kisiSayisi"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <hr className="w-75 mx-auto" />

                  <div className="row mb-3">
                    <div className="fs-5 fw-bold text-center">
                      Ön Ödeme ve İskonto
                    </div>

                    <div className="text-center mt-2 text-muted">
                      Toplam Alınan Ödeme: {newPayment}₺
                    </div>
                  </div>

                  <div className="row mb-1 px-5 px-md-0 justify-content-center">
                    <div className="form-floating col-lg-2 mb-3">
                      <select
                        className="form-select"
                        id="odemeTuru"
                        name="odemeTuru"
                        defaultValue="Nakit"
                        onChange={handleChange}
                      >
                        <option value="Nakit">Nakit</option>
                        <option value="Havale">Havale</option>
                      </select>
                      <label
                        className="form-label ms-2 fw-bold"
                        htmlFor="gelinlikFiltresi"
                        style={{ fontSize: ".95em" }}
                      >
                        Ödeme Türü
                      </label>
                    </div>
                    <div className="form-floating col-lg-2 mb-3">
                      <Field
                        type="text"
                        name="yeniOdemeAlinan"
                        placeholder="Yeni Ön Ödeme"
                      >
                        {({ field }) => (
                          <InputMask
                            {...field}
                            type="text"
                            className="form-control"
                            id="yeniOdemeAlinan"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={(e) => {
                              handlePaymentChange(e);
                              handleChange(e);
                            }}
                          />
                        )}
                      </Field>
                      <label
                        className="form-check-label ms-2 "
                        htmlFor="yeniOdemeAlinan"
                      >
                        Yeni Ön Ödeme
                      </label>

                      <ErrorMessage
                        name="yeniOdemeAlinan"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-2 mb-3">
                      <Field
                        type="text"
                        name="odemeEkstra"
                        placeholder="Ekstra Ödeme"
                      >
                        {({ field }) => (
                          <InputMask
                            {...field}
                            type="text"
                            className="form-control"
                            id="odemeEkstra"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={(e) => {
                              handleExtraPriceChange(e);
                              handleChange(e);
                            }}
                          />
                        )}
                      </Field>
                      <label
                        className="form-check-label ms-2 "
                        htmlFor="odemeEkstra"
                      >
                        Ek Ücret
                      </label>

                      <ErrorMessage
                        name="odemeEkstra"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-2 mb-3">
                      <Field
                        type="text"
                        name="odemeIndirim"
                        placeholder="İskonto"
                      >
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="odemeIndirim"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>
                      <label
                        className="form-check-label ms-2 "
                        htmlFor="odemeIndirim"
                      >
                        İskonto
                      </label>
                      <ErrorMessage
                        name="odemeIndirim"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <hr className="w-75 mx-auto" />
                  <div className="row mt-3 px-md-0 px-4 justify-content-center">
                    <div
                      className="alert alert-warning col-lg-9 px-4 py-3"
                      role="alert"
                    >
                      <i className="bi bi-info-circle-fill me-2" />
                      Sözleşmeyi oluştururken girilen fiyat bilgileriyle
                      (gelinlik modeli fiyatları, ekstra kaftan, albüm
                      seçenekleri) güncel fiyat bilgileri aynı değilse, fiyatlar
                      sarı renkle gösterilir ve sözleşmeyi güncellediğinizde bu
                      fiyatlar değişmez. Fiyatı değiştirmek için başka bir
                      seçenek seçmelisiniz.
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              İptal Et
            </Button>
            <Button variant="primary" onClick={handleSubmitOutsideForm}>
              Güncelle
            </Button>
          </Modal.Footer>
        </>
      </Modal>
      {isNewModalOpen && contractData && (
        <>
             <UpdatePhotoContractModal  
                show={setIsNewModalOpen}
                handleClose={() => setIsNewModalOpen(false)}
                data={contractData}
              />

          
        </>
      )}
      <ToastContainer
        position="top-center"
        autoClose={450}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        transition:Flip
      />
    </>
  );
}
