import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import React, { Fragment, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import fetchWrapper from '../../Utils/fetchWrapper'; // fetchWrapper'ı import et
function Login({ onRedirect }) {
  const ValidationSchema = Yup.object().shape({
    username: Yup.string().required("Kullanıcı adı gerekli"),
    password: Yup.string().required("Şifre gerekli"),
  });

  const [initialValues, setInitialValues] = useState({
    username: "",
    password: "",
  });

   const handleSubmit = async (values, { setSubmitting }) => {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetchWrapper(`${apiUrl}/v2/auth/login`, {
            method: "POST",
            body: JSON.stringify({
                username: values.username,
                password: values.password,
              }),
          });

          if (response.ok) {
              const data = await response.json();
                Cookies.set('authToken', data.token, { expires: 1 });
                onRedirect();
          } else if (response.status === 401) {
            toast.error("Kullanıcı adı veya şifre yanlış", {
              position: "top-center",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else {
            toast.error("Beklenmedik hata", {
              position: "top-center",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        } catch (error) {
          console.log("error", error);

          toast.error("Sunucuya bağlanırken bir hata meydana geldi", {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
        await new Promise((resolve) => setTimeout(resolve, 50));

        setSubmitting(false);
  };


  return (
    <Fragment>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="loginContainer container bg-light shadow border rounded-3  w-100 p-3 p-md-5"
          style={{ maxWidth: "500px" }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="row mt-2">
                  <div className="col fs-4 fw-bold">Giriş Yap</div>
                </div>
                <div className="row mt-3 pt-4">
                  <div className="form-floating col-md mb-3">
                    <Field
                      type="text"
                      className="form-control"
                      id="username"
                      name="username"
                      placeholder="Kullanıcı Adı"
                    />
                    <label className="form-label ms-3" htmlFor="username">
                      Kullanıcı Adı
                    </label>
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="text-danger mt-1 ms-1"
                    />
                  </div>
                </div>
                <div className="row mt-md-2">
                  <div className="form-floating col-md mb-3">
                    <Field
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Prenses Gelinlik"
                    />
                    <label className="form-label ms-3" htmlFor="password">
                      Şifre
                    </label>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-danger mt-1 ms-1"
                    />
                  </div>
                </div>

                <div className="row mt-2 mb-2">
                  <div className="col">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-success shadow fw-bold"
                    >
                      <i className="bi bi-door-open-fill me-1" />
                      Giriş Yap
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition:Flip
      />
    </Fragment>
  );
}

export default Login;