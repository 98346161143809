import React, { useState } from "react";
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import fetchWrapper from "../../../Utils/fetchWrapper";

function AddExtra({ reload, setReload, onClose }) {
    const [error, setError] = useState(null);

    const initialValues = {
        name: "",
        price: "",
        description: "",
    };

    const ValidationSchema = Yup.object().shape({
        name: Yup.string().required("Bu alanı doldurmak zorundasınız."),
        price: Yup.number()
            .typeError("Salon ücreti rakamlardan oluşmalıdır")
            .min(0.01, "Salon ücreti sıfırdan küçük olamaz.")
            .required("Bu alanı doldurmak zorundasınız."),
        description: Yup.string().required("Bu alanı doldurmak zorundasınız."),
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const response = await fetchWrapper(process.env.API_URL + "/extraPackages/create", {
                method: "POST",
                body: JSON.stringify({
                    name: values.name,
                    price: parseFloat(values.price),
                    description: values.description
                })
            });

            if(response.ok) {
                setError(null); // Clear any existing errors
                onClose(); // Ensure modal closes immediately after success
                resetForm();
                setReload(true);
            } else {
                toast.error("Paket eklenirken bir hata meydana geldi", {
                    position: "top-center",
                    autoClose: 500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            }
            setError(null);

        } catch (error) {
            setError("Paket eklenirken bir hata meydana geldi");
        } finally {
            await new Promise((resolve) => setTimeout(resolve, 500));
            setSubmitting(false);
        }
    };

    return (
        <div className="container bg-light shadow border rounded-3 p-3 p-md-5 mb-5" style={{ maxWidth: "600px" }}>
      
                <Formik
                    initialValues={initialValues}
                    validationSchema={ValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {() => (
                        <Form>
                            <div className="row mb-3">
                                <div className="fs-5 fw-bold text-center">
                                    Ürün Bilgileri
                                </div>
                            </div>
                            <div className="row px-5 px-md-0 mb-1 justify-content-center">
                                <div className="form-floating col-lg-10 mb-3">
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        placeholder="Salon Adı"
                                        autoComplete="off"
                                    />
                                    <label className="form-label ms-3" htmlFor="name">
                                        Ürün Adı
                                    </label>
                                    <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="text-danger mt-1 ms-1"
                                    />
                                </div>
                            </div>

                            <div className="row px-5 px-md-0 mb-1 justify-content-center">
                                <div className="form-floating col-lg-10 mb-3">
                                    <Field
                                        type="price"
                                        className="form-control"
                                        id="price"
                                        name="price"
                                        placeholder="Salon Ücreti"
                                        autoComplete="off"
                                    />
                                    <label className="form-label ms-3" htmlFor="price">
                                        Ürün Ücreti
                                    </label>
                                    <ErrorMessage
                                        name="price"
                                        component="div"
                                        className="text-danger mt-1 ms-1"
                                    />
                                </div>
                            </div>

                            <div className="row px-5 px-md-0 mb-1 justify-content-center">
                                <div className="form-floating col-lg-10 mb-3">
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="description"
                                        name="description"
                                        placeholder="Ürün Açıklama"
                                        autoComplete="off"
                                    />
                                    <label className="form-label ms-3" htmlFor="description">
                                        Açıklama
                                    </label>
                                    <ErrorMessage
                                        name="description"
                                        component="div"
                                        className="text-danger mt-1 ms-1"
                                    />
                                </div>
                            </div>

                            <div className="row mt-2 mb-2 d-flex justify-content-end">
                                <div className="col-auto">
                                    <button
                                        type="submit"
                                        className="btn btn-success shadow fw-bold"
                                    >
                                        <i className="bi bi-save-fill me-1" />
                                        Kaydet
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button
                                        type="button"
                                        className="btn btn-secondary shadow fw-bold"
                                        onClick={onClose}
                                    >
                                        <i className="bi bi-x-circle-fill me-1" />
                                        Vazgeç
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
        </div>
    );
}

export default AddExtra;