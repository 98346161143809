import React, { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import "./NewPrintCanvas.css";
import { usePrintContext } from "../contextWrapper/PrintContext";

const NewLiteBridalPrintCanvas = () => {
  const { printData, switchBackToReality, shouldPrint, setShouldPrint } =
    usePrintContext();

  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (shouldPrint === "lite-bridal") {
      handlePrint();

      switchBackToReality();

      setShouldPrint(false);
    }
  }, [
    printData,
    shouldPrint,
    handlePrint,
    switchBackToReality,
    setShouldPrint,
  ]);

  return (
    <>
      <div className="container-fluid bg-white border mt-3 d-none">
        <div ref={componentRef} className="" id="printCanvas">
          <div className="row">
            <div className="col-3 mt-5 ms-4 ps-4 pe-0">
              {printData.aciklama && (
                <>
                  <div className="fs-5 fw-bold">AÇIKLAMA:</div>
                  <div
                    className="mt-2"
                    style={{
                      maxHeight: "100px",
                      fontSize: ".75em",
                      lineHeight: "1.2em",
                    }}
                  >
                    {printData.aciklama}
                  </div>
                </>
              )}
            </div>
            <div className="col mt-5 ps-0 pe-0 me-0">
              <div className="col text-center fs-1 fw-bold">
                ALTINPARK GELİNLİK
              </div>
              <div
                className="col text-center fs-2 fw-bold"
                style={{ lineHeight: "2em" }}
              >
                TOPTAN VE PERAKENDE
              </div>
              <div
                className="col text-center mb-2"
                style={{ fontSize: "1.2em" }}
              >
                KAMPANYA PAKET SÖZLEŞMESİ
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col ms-4">
              <b className="pe-1">SÖZLEŞME TARİHİ:</b>
              {moment(printData.sozlesmeTarihi).format("DD.MM.YYYY")}
            </div>
            <div className="col text-end me-4">
              <b className="pe-1">SIRA NO:</b>
              150{printData.contractID}
            </div>
          </div>

          <hr className="mx-4 my-2" />

          <div className="row">
            <div className="col-3 ms-4 ps-4 me-2 pe-0 border-end">
              {/*MÜŞTERİ BİLGİLERİ BAŞLANGIÇ*/}
              <div className="row ps-2" style={{ lineHeight: "1.55em" }}>
                <div className="row fw-bold fs-5">MÜŞTERİ</div>
                <div className="row fw-bold fs-5">BİLGİLERİ</div>
              </div>
              <div
                className="row mt- pt-4"
                style={{
                  fontSize: ".85em",
                  letterSpacing: "0px",
                  lineHeight: "1.45em",
                }}
              >
                <div className="row ps-3 pe-0">
                  <div className="row">
                    <div className="col ps-3">DAMAT</div>
                  </div>
                  <div className="row">
                    <div className="col fw-bold">İSİM SOYİSİM:</div>
                  </div>
                  <div
                    className="row ps-4 pt-1 pe-0"
                    style={{ height: "50px" }}
                  >
                    {printData.damatAdi}
                  </div>
                  <div className="row">
                    <div className="col">TELEFON:</div>
                  </div>
                  <div className="row ps-4 pe0" style={{ height: "40px" }}>
                    {printData.damatTelefonu}
                  </div>
                  <div className="row">
                    <div className="col ps-3">GELİN</div>
                  </div>
                  <div className="row">
                    <div className="col fw-bold">İSİM SOYİSİM:</div>
                  </div>
                  <div className="row ps-4 pe-0" style={{ height: "45px" }}>
                    {printData.gelinAdi}
                  </div>

                  <div className="row">
                    <div className="col">TELEFON:</div>
                  </div>
                  <div className="row ps-4 pe-0" style={{ height: "35px" }}>
                    {printData.gelinTelefonu}
                  </div>

                  <div className="row">
                    <div className="col">DÜĞÜN TARİHİ:</div>
                  </div>
                  <div className="row ps-4 pe-0" style={{ height: "35px" }}>
                    {moment(printData.dugunTarihi).format("DD.MM.YYYY")}
                  </div>
                  <div className="row">
                    <div className="col fw-bold" style={{ fontSize: "1.25em" }}>
                      ADRES:
                    </div>
                  </div>
                  <div
                    className="row ps-4 pe-0"
                    style={{ height: "95px", lineHeight: "1.35em" }}
                  >
                    {printData.adres}
                  </div>
                </div>
                <hr className="" style={{ width: "75%" }} />
                <div className="row">
                  <div className="col fw-bold" style={{ fontSize: "1.35em" }}>
                    İRTİBAT:
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col fw-bold" style={{ fontSize: "1.35em" }}>
                    GELİNLİK:
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col fw-bold pt-2"
                    style={{
                      height: "50px",
                      fontSize: "1.35em",
                      letterSpacing: ".8px",
                    }}
                  >
                    {process.env.GELINLIK_NO}
                  </div>
                </div>
                <div className="row">
                  <div className="col fw-bold" style={{ fontSize: "1.35em" }}>
                    FOTOĞRAF:
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col fw-bold pt-2"
                    style={{
                      height: "50px",
                      fontSize: "1.35em",
                      letterSpacing: ".8px",
                    }}
                  >
                    {process.env.FOTO_NO}
                  </div>
                </div>
                <hr className="" style={{ width: "75%" }} />
                <div className="row">
                  <div className="col fw-bold fs-5 text-center py-2">
                    ALTINPARK {process.env.BASLIK}
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col fw-bold pt-2 pb-2"
                    style={{ fontSize: "1.25em" }}
                  >
                    ADRES:
                  </div>
                </div>
                <div className="row ps-4 fw-bold mb-2">
                  GÖKÇE ÖREN MAH. ESKİ ORHANELİ YOLU 6.KM OSMANGAZİ/BURSA
                </div>
                <hr className="" style={{ width: "75%" }} />
              </div>
              {/*MÜŞTERİ BİLGİLERİ BİTİŞ*/}
            </div>
            <div
              className="col ms-1 d-flex flex-column"
              style={{ fontSize: ".95em" }}
            >
              <div className="row ps-2">
                {/*GELİNLİK BİLGİLERİ BAŞLANGIÇ*/}
                <div className="col fw-bold mt-3" style={{ fontSize: "1.1em" }}>
                  • GELİNLİK BİLGİLERİ
                </div>{" "}
              </div>
              <div className="row ms-1" style={{ fontSize: ".85em" }}>
                <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                  GELİNLİK:
                </div>
                {printData.gelinlikModeli === "Ağ Kesim" ? (
                  <div className="col-auto fw-bold my-auto ps-0 pe-1">
                    AĞ KESİM
                    <i className="bi bi-x-circle ms-2 fs-5" />
                  </div>
                ) : (
                  <div className="col-auto fw-bold my-auto ps-0 pe-1">
                    AĞ KESİM
                    <i className="bi bi-circle ms-2 fs-5" />
                  </div>
                )}

                {printData.gelinlikModeli === "Helen" ? (
                  <div className="col-auto fw-bold ps-0 pe-1">
                    HELEN
                    <i className="bi bi-x-circle ps-2 fs-5" />
                  </div>
                ) : (
                  <div className="col-auto fw-bold ps-0 pe-1">
                    HELEN
                    <i className="bi bi-circle ps-2 fs-5" />
                  </div>
                )}
                {printData.gelinlikModeli === "Prenses" ? (
                  <div className="col-auto fw-bold ps-0 pe-1">
                    PRENSES
                    <i className="bi bi-x-circle ms-2 fs-5" />
                  </div>
                ) : (
                  <div className="col-auto fw-bold ps-0 pe-1">
                    PRENSES
                    <i className="bi bi-circle ms-2 fs-5" />
                  </div>
                )}
                {printData.gelinlikModeli === "Özel Dikim" ? (
                  <div className="col-auto fw-bold ps-0 pe-0">
                    ÖZEL DİKİM
                    <i className="bi bi-x-circle ms-2 fs-5" />
                  </div>
                ) : (
                  <div className="col-auto fw-bold ps-0 pe-0">
                    ÖZEL DİKİM
                    <i className="bi bi-circle ms-2 fs-5" />
                  </div>
                )}
              </div>
              {/*GELİNLİK BİLGİLERİ BİTİŞ*/}
              {/*KAFTAN BİLGİLERİ BAŞLANGIÇ*/}
              <div className="row ms-1 ps-2" style={{ fontSize: ".87em" }}>
                {printData.kaftanID ? (
                  <div className="col-4 fw-bold ps-2 my-auto me-3">
                    <i className="bi bi-x-circle fs-5 me-1" />
                    KAFTAN NO: {printData.kaftanID}
                  </div>
                ) : (
                  <div className="col-4 fw-bold ps-2 my-auto me-3">
                    <i className="bi bi-circle fs-5 me-1" />
                    KAFTAN NO:
                  </div>
                )}

                {printData.kinalikID ? (
                  <div className="col fw-bold my-auto">
                    <i className="bi bi-x-circle fs-5 me-1 ms-1" />
                    KINALIK NO: {printData.kinalikID}
                  </div>
                ) : (
                  <div className="col fw-bold my-auto">
                    <i className="bi bi-circle fs-5 me-1 ms-1" />
                    KINALIK NO:
                  </div>
                )}
              </div>
              {/*KAFTAN BİLGİLERİ BİTİŞ*/}
              {/*GELİNLİK TESLİMATI BİLGİLERİ BAŞLANGIÇ*/}
              <div className="row ms-1 ps-2" style={{ fontSize: ".87em" }}>
                <div className="col-6">
                  <div className="row">
                    <div className="col-auto fw-bold ps-2 pe-0">
                      GELİNLİK TESLİMATI:
                    </div>
                    <div className="col-auto ps-1">
                      {printData.gelinlikTeslimati &&
                        moment(printData.gelinlikTeslimati).format(
                          "DD.MM.YYYY"
                        )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto fw-bold ps-2 pe-0">
                      DAMATLIK TESLİMATI:
                    </div>
                    <div className="col-auto ps-1">
                      {printData.damatlikTeslimati &&
                        moment(printData.damatlikTeslimati).format(
                          "DD.MM.YYYY"
                        )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto fw-bold ps-2 pe-0">
                      KAFTAN - KINALIK TESLİMATI:
                    </div>
                    <div className="col-auto ps-1">
                      {printData.kinalikKiralikBaslangic &&
                        moment(printData.kinalikKiralikBaslangic).format(
                          "DD.MM.YYYY"
                        ) +
                          " - " +
                          moment(printData.kinalikKiralikBitis).format(
                            "DD.MM.YYYY"
                          )}
                    </div>
                  </div>
                  <div className="row ps-2 fw-bold">TERZİLİK DETAYLARI:</div>
                </div>
                <div
                  className="col-6 ps-2 pt-2"
                  style={{ lineHeight: "1.10em", fontSize: ".95em" }}
                >
                  <div className="row">
                    {Number(printData.gelinlikOzelDikim) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">ÖZEL DİKİM:</div>
                        <div className="col-auto ps-0">
                          {printData.gelinlikOzelDikim} ₺
                        </div>
                      </>
                    )}
                    {Number(printData.gelinlikEkstrasi) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">GELİNLİK:</div>
                        <div className="col-auto ps-0">
                          {printData.gelinlikEkstrasi} ₺
                        </div>
                      </>
                    )}
                    {Number(printData.duvakUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">DUVAK:</div>
                        <div className="col-auto ps-0">
                          {printData.duvakUcreti} ₺
                        </div>
                      </>
                    )}
                    {Number(printData.gomlekUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">GÖMLEK:</div>
                        <div className="col-auto ps-0">
                          {printData.gomlekUcreti} ₺
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row">
                    {Number(printData.tacUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">TAÇ:</div>
                        <div className="col-auto ps-0">
                          {printData.tacUcreti} ₺
                        </div>
                      </>
                    )}
                    {Number(printData.terzilikUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">TERZİLİK:</div>
                        <div className="col-auto ps-0">
                          {printData.terzilikUcreti} ₺
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row">
                    {Number(printData.kaftanUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-0">KINALIK:</div>
                        <div className="col-auto ps-0 pe-0">
                          {printData.kaftanUcreti} ₺
                        </div>
                      </>
                    )}
                    {Number(printData.kuruTemizlemeUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">KURU TEM.:</div>
                        <div className="col-auto ps-0">
                          {printData.kuruTemizlemeUcreti} ₺
                        </div>
                      </>
                    )}
                  </div>
                  <div className={`row`}>
                    {Number(printData.damatlikUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">DAMATLIK:</div>
                        <div className="col-auto ps-0">
                          {printData.damatlikUcreti} ₺
                        </div>
                      </>
                    )}
                    <div className="col-auto fw-bold pe-1">TOPLAM:</div>
                    <div className="col-auto ps-0">
                      {Number(printData.gelinlikEkstrasi) +
                        Number(printData.duvakUcreti) +
                        Number(printData.tacUcreti) +
                        Number(printData.terzilikUcreti) +
                        Number(printData.kaftanUcreti) +
                        Number(printData.kuruTemizlemeUcreti) +
                        Number(printData.damatlikUcreti) || "-"}{" "}
                      ₺
                    </div>
                  </div>
                </div>
              </div>
              {/*GELİNLİK TESLİMATI BİLGİLERİ BİTİŞ*/}
              {/*TERZİLİK BİLGİLERİ BAŞLANGIÇ*/}
              {printData.gelinlikDetaylari || printData.terzilikDetaylari ? (
                <div className="row ms-1 ps-2" style={{ fontSize: ".87em" }}>
                  <div className="col">
                    {printData.gelinlikDetaylari ? (
                      <div
                        className="row px-2"
                        style={{
                          fontSize: ".85em",
                          height: "20px",
                          lineHeight: "1.2em",
                        }}
                      >
                        {printData.gelinlikDetaylari ? (
                          <>
                            {printData.gelinlikDetaylari} <br />
                          </>
                        ) : null}
                        {printData.terzilikDetaylari
                          ? printData.terzilikDetaylari
                          : ""}
                      </div>
                    ) : (
                      <div
                        className="row px-2"
                        style={{
                          fontSize: ".85em",
                          height: "20px",
                          lineHeight: "1.2em",
                        }}
                      >
                        {printData.terzilikDetaylari
                          ? printData.terzilikDetaylari
                          : ""}
                      </div>
                    )}
                  </div>
                </div>
              ) : undefined}
              {/*TERZİLİK BİLGİLERİ BİTİŞ*/}
              <hr className="" style={{ width: "95%" }} />
              <div
                className="row mb-1 mt-2 pe-2 fw-bold"
                style={{
                  lineHeight: ".80em",
                  letterSpacing: ".2px",
                }}
              >
                <div
                  className="col-12 text-center mx-auto mb-2"
                  style={{ fontSize: ".90em" }}
                >
                  SATIŞ & KİRALAMA SÖZLEŞMESİ HÜKÜMLERİ
                </div>
                <div className="col-12 pe-4" style={{ fontSize: ".70em" }}>
                  Satış için:
                  <br /> 1-Gelinlik için özel dikim seçimi ve teslimatı bu
                  sözleşme ile gerçekleştirilecek olup özel dikime ilişkin
                  detaylar yazılı olarak satıcıya bildirilecektir.
                  <br /> 2-Gelinlik, satıcı tarafından teslimat tarihinde hazır
                  edilecektir, alıcı teslimat tarihinde ürünlerini teslim
                  alacaktır. Süresi içerisinde teslim alınmayan gelinlik ile
                  ilgili satıcının herhangi bir sorumluluğu olmayacak olup alıcı
                  süresi içerisinde gelinliği teslim almazsa satıcıyı tamamen
                  ibra etmiş olacaktır. Alıcı bu sözleşme ile bu hususu kabul,
                  taahhüt ve beyan eder.
                  <br />
                  3-Özel dikim ile yapılacak gelinlikler için tarafların
                  anlaşması ve sözleşmenin imzalanması ile satıcı, anlaşılan
                  tarihte gelinliği hazırlayıp teslim etmekle, alıcı da
                  gelinliğin ücretin sözleşme tarihinde peşinen ödemekle yükümlü
                  hale gelecektir. Sözleşme kurulduktan sonra haklı br sebep
                  olmaksızın sözleşmenin alıcı tarafından tek taraflı
                  feshedilmesi halinde anlaşılan paket ücretinin tamamı alıcı
                  tarafından satıcıya ödenecektir. Alıcı bu sözleşme ile bu
                  hususu kabul, taahhüt ve beyan eder.
                  <br />
                  4-İşbu sözleşme ile yapılan anlaşmalar Türk Borçlar Kanunu
                  uyarınca haklı br sebep gösterilmeksizin feshedilemez.
                  Yukarıda yazılı hususlara halel gelmemesi kaydıyla, alıcı
                  tarafından tek taraflı fesih yapılsa dahil kapora bedeli iade
                  edilmeyecektir. Alıcı bu sözleşme ile bu hususu kabul, taahhüt
                  ve beyan eder.
                </div>
                <div className="col-12 pe-4 mt-2" style={{ fontSize: ".70em" }}>
                  Kiralama için:
                  <br />
                  1-Kaftan kiralamaları bu sözleşme ile gerçekleştirilecek olup
                  sözleşme ile kiraya veren; süresinde ürünü teslim etmeyi,
                  kiralayan; kiralama ücretini peşinen ödemeyi ve aşağıda yazılı
                  şekilde ürünü iade etmeyi kabul, taahhüt ve beyan eder.
                  <br />
                  2-Kira konusu ürün, taahhüt edilen tarihten 1 gün önce
                  kiralayan tarafından teslim alınacak ve kullanım tarihinden
                  bir gün sonra kiraya verene geri teslim edilecektir. Toplam
                  kiralama süreci 3 gündür. Kiralayan bu hususu kabul ve taahhüt
                  eder.
                  <br />
                  3-Kiralanan ürünün kullanımdan sonra kuru temizlemeye
                  verilerek temizlenmesi kiralayanın sorumluluğundadır.
                  Kiralayan, kira konusu ürünü kuru temizleme vasıtasıyla
                  temizlemeden kiraya verene teslim etmeyecektir. Kiralayan bu
                  hususu kabul ve taahhüt eder.
                  <br />
                  4-Kiralayan, kiraya konu ürünü kuru temizlemeye vermeden iade
                  ederse 1.000 TL kuru temizleme bedelini kiraya verene ürün
                  teslimatında ödemeyi kabul ve taahhüt eder.
                  <br />
                  5-Kiralayan tarafından teslim alınan ürünlerin kuru temizleme
                  dahil tüm sorumluluğu, kiraya verene iade edilme anına kadar
                  kiralayan üzerindedir. Kiralayan bu hususu kabul ve taahhüt
                  eder.
                  <br />
                  6-Kiralayan ürünü sözleşmede belirlenen günde ve şekilde iade
                  etmekle yükümlüdür. Kiralayan ürünü belirlenen günde iade
                  etmezse iade edilmeyen her gün için kiraya verene 1.000 TL
                  gecikme bedeli ödemekle yükümlüdür. Kiralanan ürün hiç iade
                  edilmezse yahut tahrip edilirse kiralayan, ürün bedeli olan
                  .............. TL’yi kiraya verene ödemekle yükümlüdür.
                  Kiralayan bu sözleşme ile belirtilen hususları kabul ve
                  taahhüt eder.
                </div>
                <div className="col-12 pe-4 mt-2" style={{ fontSize: ".70em" }}>
                  Özel Hükümler:
                  <br />
                  1-Altınpark dış çekim giriş ücreti paket içeriğine dahildir.
                  <br />
                  2-Paket toplam ücret ekstralar ve {printData.paketFiyati} +
                  KDV TL’dir.
                  <br />
                  3-Randevusu oluşturulmayan hiçbir hizmetten firmamız sorumlu
                  değildir. 4- Taraflar, işbu sözleşmenin uygulanmasında Türkiye
                  Cumhuriyeri kanunlarının uygulanacağını ve sözleşmeden doğan
                  uyuşmazlıklarda Bursa Mahkemeleri ve İcra Daireleri'nin
                  yetkili olacağını ve her türlü ihtilafta tarafların defter ve
                  kayıtlarının kesin delil olarak kabul edileceğini kabul ve
                  beyan ederler.
                </div>
              </div>
              <div className="mt-auto">
                <hr className="me-3 ms-1 mt-1 mb-1" style={{ width: "90%" }} />
                <div className="row mt-3">
                  <div className="col-3 fs-5 fw-bold">SATICI:</div>
                  <div className="col-4">
                    <div className="row fs-5 fw-bold">ALICI:</div>
                    <div className="row">
                      <div className="col p-0 pe-1" style={{ height: "45px" }}>
                        {printData.damatAdi}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row">
                      <div className="col p-0 fw-bold">TOPLAM:</div>
                      <div className="col p-0 mt-auto">
                        {printData.odemeToplam} ₺
                      </div>
                    </div>
                    <div className="row">
                      <div className="col p-0 fw-bold">KAPORA:</div>
                      <div className="col p-0 mt-auto ps-1">
                        {printData.odemeAlinan} ₺
                      </div>
                    </div>
                    {Number(printData.odemeIndirim) > 0 && (
                      <div className="row">
                        <div className="col p-0 fw-bold">İSKONTO:</div>
                        <div className="col p-0 mt-auto ps-1">
                          {printData.odemeIndirim} ₺
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col p-0 fw-bold">KALAN:</div>
                      <div className="col p-0 mt-auto">
                        {printData.odemeKalan} ₺
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewLiteBridalPrintCanvas;
