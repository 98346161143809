import React, { useEffect, useState } from 'react';
import { Table, Spinner, Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const PaymentList = ({ photoContractId }) => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedLogIndex, setSelectedLogIndex] = useState(null);
    const [password, setPassword] = useState('');
    const [deleteError, setDeleteError] = useState('');

    const fetchLogs = async () => {
        try {
            const response = await fetch(`${process.env.API_URL}/photoContracts/paymentList/${photoContractId}`, {
                method: 'GET',
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            
            if (!response.ok) {
                throw new Error('Veri yüklenirken bir hata oluştu');
            }
            
            const data = await response.json();
            setLogs(Array.isArray(data) ? data : []);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (photoContractId) {
            fetchLogs();
        }
    }, [photoContractId]);

    const handleDelete = async () => {
        if (!password) {
            setDeleteError('Lütfen şifre giriniz');
            return;
        }

        try {
            const response = await fetch(`${process.env.API_URL}/photoContracts/paymentDelete`, {
                method: 'POST',
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    photoContractId,
                    logIndex: selectedLogIndex,
                    password
                })
            });

            if (!response.ok) {
                throw new Error('Silme işlemi başarısız oldu');
            }

            const result = await response.json();
            if (result.success) {
                await fetchLogs(); // Listeyi yenile
                handleCloseDeleteModal();
            } else {
                setDeleteError(result.message || 'Silme işlemi başarısız oldu');
            }
        } catch (error) {
            setDeleteError(error.message);
            console.error('Silme işlemi sırasında hata:', error);
        }
    };

    const handleDeleteClick = (index) => {
        setSelectedLogIndex(index);
        setPassword('');
        setDeleteError('');
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedLogIndex(null);
        setPassword('');
        setDeleteError('');
    };

    const parseLogEntry = (entry) => {
        const parts = entry.split(' - ');
        const datePart = parts[0].split(': ')[1];
        
        let action = '';
        let amount = '';
        let paymentType = '';

        parts.forEach(part => {
            if (part.includes('Ödeme Türü')) {
                paymentType = part.split(': ')[1];
            } else if (part.includes('Alınan Ödeme' )) {
                amount = part;
            } else if (part.includes('Yeni')) {
                action = part;
            } else if (part.includes('İskonto')) {
                action = part;
            }
        });

        return {
            date: datePart,
            action: action || amount,
            paymentType: paymentType || '-'
        };
    };

    if (loading) {
        return (
            <div className="text-center p-4" onClick={e => e.stopPropagation()}>
                <Spinner animation="border" role="status" variant="primary">
                    <span className="visually-hidden">Yükleniyor...</span>
                </Spinner>
            </div>
        );
    }

    if (error) {
        return <div className="alert alert-danger m-3" onClick={e => e.stopPropagation()}>{error}</div>;
    }

    if (!logs || logs.length === 0) {
        return <div className="alert alert-info m-3" onClick={e => e.stopPropagation()}>Henüz ödeme kaydı bulunmamaktadır.</div>;
    }

    return (
        <>
            <div className="payment-logs p-3" onClick={e => e.stopPropagation()}>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Tarih</th>
                            <th>İşlem Detayı</th>
                            <th>Ödeme Türü</th>
                            <th>İşlem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs.map((log, index) => {
                            const { date, action, paymentType } = parseLogEntry(log.entry);
                            return (
                                <tr key={index}>
                                    <td>{date}</td>
                                    <td>{action}</td>
                                    <td>{paymentType}</td>
                                    <td>
                                        <Button 
                                            variant="danger" 
                                            size="sm"
                                            onClick={() => handleDeleteClick(index)}
                                        >
                                            Sil
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>

            {/* Silme Onay Modalı */}
            <Modal
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                backdrop="static"
                keyboard={false}
                size="sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ödeme Kaydını Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Şifre</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Şifre giriniz"
                        />
                        {deleteError && (
                            <div className="alert alert-danger mt-2 py-2">{deleteError}</div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        İptal
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Sil
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

PaymentList.propTypes = {
    photoContractId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired
};

export default PaymentList;